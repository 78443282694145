/* =========================================================================
Dismiss Form Message
========================================================================= */


// const initialiseSendmail = () => {
//   const dismissButton = document.querySelector('.form-message-block button');
//   const formContainers = document.querySelectorAll('.form_container');
  
//   dismissButton.addEventListener('click', event => {
//     dismissFormMessagefn();
//   })


// }

// if (document.readyState == 'loading') {
//   // still loading, wait for the event
//   document.addEventListener('DOMContentLoaded', initializeSendmail);
// } else {
//   // DOM is ready!
//   initializeSendmail();
// }

(function($) {

function dismissFormMessagefn() {
  $('.form-message-block').fadeOut(300, function () {
      $(this).remove();
  });
}

if ($('.form_container').length) {
        $('.form_container form').each(function (index) {

            $(this).attr('id', 'gfort-form_container-' + index);

            $('#gfort-form_container-' + index).submit(function () {

                var el = $(this),
                    formValues = el.serialize(),
                    formActionURL = el.attr('action'),
                    recaptchaID = el.find('.gfort-recaptcha').attr('id');

                el.find('.gfort-new-recaptcha').removeClass('gfort-new-recaptcha');
                el.find('.gfort-recaptcha').parent().addClass('gfort-new-recaptcha');
                el.find('button').addClass('add-spin');
                $.post(formActionURL, formValues, function (response) {

                    /* Form Message
                    --------------------------------------------------------- */
                    if (!$('.form-message-block').length) {
                        $('.form').prepend('<div class="form-message-block"><div class="form-message-container"></div></div>');
                    }
                    $('.form-message-container').html(response);
                    $('.form-message-block').fadeIn(100);

                    /* Handle Errors
                    --------------------------------------------------------- */
                    /* Name
                    ----------------------------------------------------- */
                    if (response.match('error-name') !== null) {
                        el.find('.contact-name').next().addClass('error');
                        el.find('button').removeClass('add-spin');
                    }

                    if (response.match('error-firstname') !== null) {
                        el.find('.contact-firstname').next().addClass('error');
                        el.find('button').removeClass('add-spin');
                    }

                    /* Phone
                    ----------------------------------------------------- */
                    if (response.match('error-phone') !== null) {
                        el.find('.contact-phone').next().addClass('error');
                        el.find('button').removeClass('add-spin');
                    }

                    /* Email
                    ----------------------------------------------------- */
                    if (response.match('error-email') !== null) {
                        el.find('.contact-email').next().addClass('error');
                        el.find('button').removeClass('add-spin');
                    }

                    /* Subject
                    ----------------------------------------------------- */
                    if (response.match('error-subject') !== null) {
                        el.find('.contact-subject').next().addClass('error');
                        el.find('button').removeClass('add-spin');
                    }

                    /* Message
                    ----------------------------------------------------- */
                    if (response.match('error-message') !== null) {
                        el.find('.contact-message').next().addClass('error');
                        el.find('button').removeClass('add-spin');
                    }

                    /* Terms
                    ----------------------------------------------------- */
                    if (response.match('error-terms') !== null) {
                        el.find('.contact-terms').next().addClass('error');
                        el.find('button').removeClass('add-spin');
                    }

                    /* Captcha
                    ----------------------------------------------------- */
                    if (response.match('error-captcha') !== null) {
                        el.find('button').removeClass('add-spin');
                    }

                    /* Success
                    --------------------------------------------------------- */
                    if (response.match('success-message') !== null) {
                        
                        // el.find('.form').remove();

                        el.find('.gfort-recaptcha').remove();
                        el.find('.gfort-new-recaptcha').append('<div class="gfort-recaptcha" id="' + recaptchaID + '"></div>');
                        // grecaptcha.render(recaptchaID, {sitekey: gfortRecaptchaSiteKey});

                        el.find('.form-control').val('').removeClass('input-filled');

                        el.find('button').removeClass('add-spin');

                        el.find('.contact-terms').attr('checked', false);
                        el.find('.contact-terms').attr('value', 'accepted');
                        el.find('.contact-terms').next().removeClass('error');
                        el[0].reset();
                        
                    }
                });
                return false;
            });

            $(this).find('input').on('focus', function () {
                $(this).next().removeClass('error');
                dismissFormMessagefn();
            });
        });
    }
})(jQuery);